<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <div id="top">
      <span class="title"> {{ $t("message.title") }}</span>
    </div>
    <div id="content" ref="messageUl">
      <ul class="list"  >
        <li
          class="item"
          @click="messageDetail(item)"
          v-for="item in list"
          :key="item.id"
        >
          <p>{{ item.createTime }}</p>
          <div class="messageDiv">
            <div class="messTop">
              <div>
                <img src="assets/self.png" />
                <span>{{ $t("message.announcement") }}</span>
              </div>
              <span class="unreadIcon" v-if="item.readOrNot == 0"
                >{{ $t("message.unread") }}
              </span>
              <span class="readIcon" v-if="item.readOrNot == 1">{{
                $t("message.read")
              }}</span>
            </div>
            <p class="fontbold">{{ item.title }}</p>
            <p class="messbottom">
              <span class="readIcon">{{ $t("message.learnMore") }}</span>
              <span> > </span>
            </p>
          </div>
        </li>
             <p class="nomore" v-if="nomoreData">{{ $t("mine.nomore") }}</p>
      </ul>
       <div class="none" v-if="list.length == 0">
          <div>
            <img src="assets/nodata.png" />
            <p>NO Data</p>
         </div>
        </div>
    </div>
    <van-tabbar
      v-model="active"
      active-color="#07c160"
      inactive-color="#999"
    >
      <van-tabbar-item  to="/home">
        <span>{{ $t("navbar.home") }}</span>
        <template #icon="props">
          <img
            :src="props.active ? 'assets/homeActive.png' : 'assets/home.png'"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/message">
        <span>{{ $t("navbar.message") }}</span>
        <template #icon="props2">
          <img
            :src="
              props2.active ? 'assets/messageActive.png' : 'assets/message.png'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/mine">
        <span>{{ $t("navbar.mine") }}</span>
        <template #icon="props3">
          <img
            :src="props3.active ? 'assets/mineActive.png' : 'assets/mine.png'"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { noticeQuery } from "@network/api";
export default {
  name: "",
  components: {},
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      nomoreData:false,
      loading:false,
      title: "我的",
      name: "Lorem ipsum ",
      id: "店员",
      ts: "Kexin Communication Store, Yishan Town, Jingtai County, Baiyin City",
      num: 32,
      money: 3200,
      active: 1,
      list: [],
      totalnum: 0,
      pagNum: 0,
       params:{
          size: 10,
          page: 1,
        }
    };
  },
  mounted() {
    this.getPath();
    this.$nextTick(() => {
      this.$refs.messageUl.addEventListener("scroll", this.handleScroll, true);
    });
  },

  methods: {
    getPath() {      
      if (this.$route.path == "/message") {
        this.active = 1;
        this.loading = true;
        noticeQuery(this.params).then((res) => {
        this.loading = false;
        if(res.code === '10011'){
            return
          }
          if (res.code == "000000") {
            this.list = res.data.records;
          } else {
            this.$toast(res.message);
          }
        })
        .catch((err) => {
        this.loading = false;
          this.$toast(err.message);
        });
      }
    },
            //页面滑到底部需要调用的方法
    handleScroll() {
      //下面这部分兼容手机端和PC端
      var scrollTop = this.$refs.messageUl.scrollTop; //滚动条的位置
      var windowHeitht = this.$refs.messageUl.clientHeight; //在页面上返回内容的可视高度
      var scrollHeight = this.$refs.messageUl.scrollHeight; //返回整个元素的高度（包括带滚动条的隐蔽的地方）
      //是否滚动到底部的判断
      if (Math.round(scrollTop) + windowHeitht == scrollHeight) {
        this.params.page++;
        if (this.pagNum <= this.totalnum) {
          this.loading = true;
          noticeQuery(this.params)
            .then((res) => {
              this.loading = false;
                if (res.code === "10011") {
                    return;
                  }
              if (res.code == "000000") {
                res.data.records.forEach((item) => {
                  this.list.push(item);
                });
                console.log(this.list);
                this.totalnum = res.data.pages;
                this.pagNum = res.data.page;
              } else {
                this.$toast(res.message);
              }
            })
            .catch((err) => {
              this.loading = false;
              this.$toast(err.message);
            });
        } else {
          this.nomoreData = true
          return;
        }
      }
    },
    messageDetail(item) {
      this.$router.push({
        name: "messageDetail",
        params: { messageId: item.id,createTime:item.createTime,title:item.title,content:item.content },
      });
    },
  },
};
</script>
<style scoped>
#top {
  background: #fafafa;
}
#content {
  background: #fafafa;
  padding: 0 24px 15px 24px;
  box-sizing: border-box;
}
#selfmessage {
  display: flex;
  padding: 10px 0 10px 0;
}
#selfmessage img {
  width: 56px;
  height: 56px;
}

.card {
  color: #666;
  padding: 17px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}
.pid {
  display: flex;
  margin-right: 84rpx;
  flex-direction: column;
  width: calc(50vw - 30px);
  justify-content: center;
  /* align-items: center; */
  height: 96px;
  left: 24px;
  margin-right: 15px;
  top: 104px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 12px;
  padding-left: 23px;
}
.pid:nth-child(2) {
  margin-right: 0;
}

.pid p:nth-child(1) {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 11px;
}
.pid .bold {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #333333;
}
.tab {
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.tab .tabli {
  padding: 0 16px;
  width: 100%;
  position: relative;
  height: 48px;
  padding: 1;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
.tab .tabli .text {
  color: #333;
  font-size: 14px;
}
.tabli img {
  height: 16px;
  width: 16px;
}
.item > p {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #999999;
  margin: 10px 0;
}
.messageDiv {
  background: #fff;
  border-radius: 5px;
  padding: 0 16px;
}
.messTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messTop > div {
  display: flex;
  align-items: center;
  height: 44px;
}
.messTop > div img {
  width: 24px;
  height: 24px;
}
.messTop > div span {
  font-size: 13px;
  color: #333;
  margin-left: 15px;
}
.unreadIcon {
  font-size: 13px;
  line-height: 18px;
  color: #2dd070;
}
.readIcon {
  font-size: 13px;
  line-height: 18px;
  color: #cccccc;
}
.normalFont {
  max-height: 23px;
  font-size: 13px;
  line-height: 23px;
  margin-bottom: 10px;
  color: #999;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.fontbold {
  font-weight: bold;
  font-size: 16px;
  line-height: 45px;
  color: #333;
  height: 45px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.messbottom {
  box-shadow: 0px -0.5px 0px rgb(0 0 0 / 5%);
  height: 44px;
  line-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messbottom span {
  font-size: 13px;
  color: #999;
}
</style>
